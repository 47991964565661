/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { apiUrl, cmscontentURL,mediaUrl,template1,template2,template3} from'../Config/Config';

import { GET_SETTINGDATA, GET_UPDATESETTING, GET_FONTNAME } from '../../actions';
import Select from 'react-select';
import axios from 'axios';
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import Addinputsimple from './Addinputsimple';
import CKEditor from 'ckeditor4-react';

import MyEditor from '../Helpers/MyEditor';
import MyEditor1 from '../Helpers/MyEditor1';
import MyEditor2 from '../Helpers/MyEditor2';
import MyEditor3 from '../Helpers/MyEditor3';

class Setting extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			settings_id:'',
			settings_site_title:'',
			settings_from_name:'',
			settings_admin_email:'',
			settings_from_email:'',
			settings_mailpath:'',
			settings_company_address:'',
			settings_commission_percentage:'',
			settings_smtp_host:'',
			settings_smtp_user:'',
			settings_smtp_pass:'',
			settings_smtp_port:'',
			settings_username:'',
			settings_mobileno:'',
			settings_email_footer:'',
			settings_commision_amount:'',
			smtp_checked:false,
			Loading: false,
			displayColorPicker: false,
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			},
			hexColor:'#f17013',
			settings_public_key:'',
			settings_product_key:'',
			settings_secret_key:'',
			stripe_live_checked:false,
			stripe_sandbox_checked:false,
			settings_embedcode:'',
			setting_goal_amount:'',
			settings_access_code:'',
			count_domain:'',
			st_position_left:'',
			st_position_right:'',
			st_position_top:'',
			st_position_bottom:'',
			domainSets:'',
			chat_name:'',
			letter_spacing:'',
			
			removeID:[
        	],
			fonttype : [],
			selectedFont:'',
			fontvalue:'',
			st_position_checked:true,
			st_position:'',
			supporter_additional_fee:'',
			product_layout2_checked:true,
			product_layout3_checked:false,
			google_analystic_code:'',
			category_icons_yes:true,
			category_icons_no:false,
			product_view_list:true,
			product_view_grid:false,
			dashboard_view_list:true,
			dashboard_view_grid:false,
			header_cat_limit:1,
			ai_fine_tune_model_id:'',
			payment_form:'normal',

			cancellation_fees_type:"flat",
			aichat_type:"text",
			cancellation_hr:'',
			cancellation_fee:'',
			reschedule_fees_type:"flat",
			reschedule_fee:'',
			reschedule_hr:'',
			np_theme:'',
			location_distance:'',
			faqmodelguest: '',
			faqmodeluser: '',
			faqmodeldoctor: '',
			faqmodelcompany:'',
			faqmodeltechnician:'',
			selectedfaqmodeluserval:'',
			selectedfaqmodelguestval:'',
			selectedfaqmodeldoctorval:'',
			selectedfaqmodelcompanyval:'',
			selectedfaqmodeltechnicianval:'',
			selectedfaqmodelguest:'',
			selectedfaqmodeldoctor:'',
			selectedfaqmodelcompany:'',
			selectedfaqmodeltechnician:'',
			selectedfaqmodeluserval:'',
			mailsend_doctor_time:'',	
			basic_consultant_fee:'',		
			consent_form_agree:'',
			dashboard_view:1,
			dashboard:'grid',
			product_view_checkbox:'show',	
			product_doctor_checkbox:'show',
			selectedinvite_discount:'',
			discountvalue:'',
			invite_discount_list : [],
			dogimage:'',
			dogimage_name: '',
			dogimage_preview: '',
			catimage:'',
			catimage_name: '',
			catimage_preview: '',
			stroke_color: '#e66465',
			invite_friend_description:'',
			invite_friend_short_description:'',
			invite_friend_heading:'',
			inviteimage_name:'',
			inviteimage_preview:'',
			inviteimage:'',
			selected_template:'',
			selected_templateval:'',
			dahboard_template:[{value:1,label:'Template 1'},{value:2,label:'Template 2'},{value:3,label:'Template 3'}],
			plugin_size:'600',
			company_consultant_fee:'',
			shopping_fee:'',
			display_tab:'mainTab',
			ck_description:'AAAAAAAAA',
		};
		var qs = require('qs');
		
		var postobject = {
			admin_id:  localStorage.getItem("admin_id")
		};

		this.props.getSettingData(qs.stringify(postobject));

		this.props.getFontName();
		this.myCallback();
		this.handleInputChange  = this.handleInputChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind(this);
		this.onChangeHandlerDog = this.onChangeHandlerDog.bind(this);
		this.onChangeHandlerCat = this.onChangeHandlerCat.bind(this);
		this.onChangeHandlerinviteimage = this.onChangeHandlerinviteimage.bind(this);
		this.onEditorChangeinvitefrndShortDesc = this.onEditorChangeinvitefrndShortDesc.bind(this);
		this.onEditorChangeinvitefrndHead =this.onEditorChangeinvitefrndHead.bind(this);
		this.onEditorChangeinvitefrndDesc =this.onEditorChangeinvitefrndDesc.bind(this);
		this.onEditorAIChange = this.onEditorAIChange.bind( this );
    }
	onChangeHandlerDog=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			dogimage_name: file.name,
			dogimage_preview: reader.result,
			dogimage: file
		  });
		};
		reader.readAsDataURL(file);
		const allowedExtension1 = ["png"];
		const fileExtension1 = file.name.split(".")[1];

		if (allowedExtension1.includes(fileExtension1)) {
			this.setState({dogpngerror:false});
			$('.dogimage_success').html('');
		} else {
			this.setState({dogpngerror:true});
			$('.dogimage_success').html('Incorrect file extension');
			setTimeout(function () {
				scrollToTopValidate();
			}, 100);
			return false;
		}
	}

	onChangeHandlerCat=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			catimage_name: file.name,
			catimage_preview: reader.result,
			catimage: file
		  });
		};
		reader.readAsDataURL(file);
		const allowedExtension = ["png"];
		const fileExtension = file.name.split(".")[1];

		if (allowedExtension.includes(fileExtension)) {
			$('.catimage_success').html('');
			this.setState({catpngerror:false});
		} else {
			this.setState({catpngerror:true});
			$('.catimage_success').html('Incorrect file extension');
			setTimeout(function () {
				scrollToTopValidate();
			}, 100);
			return false;
		}
	}
	onChangeHandlerinviteimage=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			inviteimage_name: file.name,
			inviteimage_preview: reader.result,
			inviteimage: file
		  });
		};
		reader.readAsDataURL(file);
		const allowedExtension = ["png","jpg","jpeg"];
		const fileExtension = file.name.split(".")[1];

		if (allowedExtension.includes(fileExtension)) {
			$('.inviteimage_success').html('');
		} else {
			$('.inviteimage_success').html('Incorrect file extension');
			setTimeout(function () {
				scrollToTopValidate();
			}, 100);
			return false;
		}
	}
	onEditorChange( evt ) {
    	var callfunc = this;
    	setTimeout(function () {
			callfunc.setState( {
				consent_form_agree: evt.editor.getData()
			});
		},1000);     
    }
	onEditorChangeinvitefrndHead( evt ) {
    	var callfunc = this;
    	setTimeout(function () {
			callfunc.setState( {
				invite_friend_heading: evt.editor.getData()
			});
		},1000);     
    }
	onEditorChangeinvitefrndDesc( evt ) {
    	var callfunc = this;
    	setTimeout(function () {
			callfunc.setState( {
				invite_friend_description: evt.editor.getData()
			});
		},1000);     
    }
	onEditorChangeinvitefrndShortDesc(evt){
    	var callfunc = this;
    	setTimeout(function () {
			callfunc.setState( {
				invite_friend_short_description: evt.editor.getData()
			});
		},1000);     
    }

    handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'settings_mail_from_smtp'){
      	 this.setState({smtp_checked: !this.state.smtp_checked});
      }	
      if(name === 'settings_enable_live'){
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      	  this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      }	
      if(name === 'settings_enable_sandbox'){
      	 this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      }	
      if(name === 'product_layout'){
      	 this.setState({product_layout2_checked: !this.state.product_layout2_checked});
      	 this.setState({product_layout3_checked: !this.state.product_layout3_checked});
      }

	  if(name === 'category_icons'){
		this.setState({category_icons_yes: !this.state.category_icons_yes});
		this.setState({category_icons_no: !this.state.category_icons_no});
     }
	 if(name === 'product_view'){
		this.setState({product_view_list: !this.state.product_view_list});
		this.setState({product_view_grid: !this.state.product_view_grid});
     }
	 if(name === 'dashboard'){
		this.setState({dashboard_view_list: !this.state.dashboard_view_list});
		this.setState({dashboard_view_grid: !this.state.dashboard_view_grid});
     }
      if(name === 'st_position'){
      	this.setState({st_position_checked: !this.state.st_position_checked});
      }
    }
	
    componentDidMount() {
		var admin_id =  localStorage.getItem("admin_id")
		axios.get(apiUrl+"adminpanel/getchatmodel?admin_id="+admin_id)
			.then(res => {			
				if(res.data.status == 'success'){
					this.setState({
						faqmodelguest: res.data.faqmodelguest,
						faqmodeluser: res.data.faqmodeluser,
						faqmodeldoctor: res.data.faqmodeldoctor,
						faqmodelcompany: res.data.faqmodelcompany,
						faqmodeltechnician: res.data.faqmodeltechnician,
					});			
				

				}
			});

      document.title = PageTitle('Settings');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	  let body = document.querySelectorAll("body")[0];
	if(localStorage.getItem('admin_id')){
		body.classList.remove("body-theme");
	}else{
		body.classList.add("body-theme");
	}
     }
	 


    handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				  
				const formPayload = this.state;

				var qs = require('qs');
				if(this.state.smtp_checked === true){
					var settings_mail_from_smtp = '1';
				}else{
					 settings_mail_from_smtp = '0';
				}
				if(this.state.stripe_live_checked === true){
					var settings_enable_live = '1';
				}else{
					 settings_enable_live = '0';
				}

				if(this.state.st_position_checked === true){
					var plugin_position = 'fixed';
				}else{
					 plugin_position = 'absolute';
				}
                
				var fonttype;
				if(formPayload.selectedFont[0]==undefined){
					fonttype = formPayload.selectedFont.value;
				}else{
					fonttype = formPayload.selectedFont[0].value;
				}

				var invitediscount;
				if(formPayload.selectedinvite_discount[0]==undefined){
					invitediscount = formPayload.selectedinvite_discount.value;
				}else{
					invitediscount = formPayload.selectedinvite_discount[0].value;
				}


				var product_layout = '';
				if(this.state.product_layout3_checked === true){
					 product_layout = '3';
				}else{
					 product_layout = '2';
				}

				var show_category = '';
				if(this.state.category_icons_yes === true){
					show_category = 'yes';
				}else{
					show_category = 'no';
				}
				var product_view = '';
				if(this.state.product_view_list === true){
					product_view = '0';
				}else{
					product_view = '1';
				}
				var dashboard = '';
				if(this.state.dashboard_view_list === true){
					dashboard = 'list';
				}else{
					dashboard = 'grid';
				} 
				var postObject = {
					settings_id: formPayload.settings_id,
					settings_site_title:formPayload.settings_site_title,
					settings_from_name:formPayload.settings_from_name,
					settings_admin_email:formPayload.settings_admin_email,
					settings_from_email:formPayload.settings_from_email,
					settings_mailpath:formPayload.settings_mailpath,
					settings_company_address:formPayload.settings_company_address,
					settings_commission_percentage:formPayload.settings_commission_percentage,
					site_background: formPayload.hexColor,
					smtp_host:formPayload.settings_smtp_host,
					smtp_user:formPayload.settings_smtp_user,
					smtp_pass:formPayload.settings_smtp_pass,
					smtp_port:formPayload.settings_smtp_port,
					admin_user:formPayload.settings_username,
					admin_no:formPayload.settings_mobileno,
					settings_email_footer:formPayload.settings_email_footer,
					settings_mail_from_smtp:settings_mail_from_smtp,
					settings_public_key:formPayload.settings_public_key,
					settings_product_key:formPayload.settings_product_key,
					setting_goal_amount:formPayload.setting_goal_amount,
					supporter_additional_fee:formPayload.supporter_additional_fee,
					settings_secret_key:formPayload.settings_secret_key,
					settings_enable_live:settings_enable_live,
					st_position_left:formPayload.st_position_left,
					st_position_right:formPayload.st_position_right,
					chat_name:formPayload.chat_name,
					letter_spacing:formPayload.letter_spacing,
					st_position_top:formPayload.st_position_top,
					st_position_bottom:formPayload.st_position_bottom,
					font 		  : fonttype,
					settings_commision_amount: formPayload.settings_commision_amount,
					location_distance: formPayload.location_distance,
					plugin_position: plugin_position,
					google_analystic_code: formPayload.google_analystic_code,
					product_layout: product_layout,
					removeId: formPayload.removeID,
					show_category_icons: show_category,
					product_view: product_view,
					header_cat_limit: formPayload.header_cat_limit,
					ai_fine_tune_model_id: formPayload.ai_fine_tune_model_id,
					count_domain: formPayload.count_domain,
					payment_form: formPayload.payment_form,
					reschedule_fee: formPayload.reschedule_fee,
					reschedule_hr: formPayload.reschedule_hr,
					mailsend_doctor_time: formPayload.mailsend_doctor_time,
					basic_consultant_fee: formPayload.basic_consultant_fee,
					np_theme: formPayload.np_theme,
					cancellation_fee: formPayload.cancellation_fee,
					cancellation_hr: formPayload.cancellation_hr,
					cancellation_fees_type:formPayload.cancellation_fees_type,
					aichat_type:formPayload.aichat_type,
					reschedule_fees_type:formPayload.reschedule_fees_type,
					settings_faq_user_model_id:formPayload.selectedfaqmodeluserval,
					settings_faq_guest_model_id:formPayload.selectedfaqmodelguestval,
					settings_faq_doctor_model_id:formPayload.selectedfaqmodeldoctorval,
					settings_faq_company_model_id:formPayload.selectedfaqmodelcompanyval,
					settings_faq_technician_model_id:formPayload.selectedfaqmodeltechnicianval,
					consent_form_agree: formPayload.consent_form_agree,
					dashboard_view: formPayload.dashboard_view,
					dashbaord_template:formPayload.selected_templateval,
					dashboard:dashboard,
					product_view_checkbox:formPayload.product_view_checkbox,
					product_doctor_checkbox:formPayload.product_doctor_checkbox, 
					invite_friends_discount:invitediscount,
					dogimage : formPayload.dogimage,
					catimage : formPayload.catimage,
					invite_friend_description: formPayload.invite_friend_description,
					invite_friend_short_description: formPayload.invite_friend_short_description,
					invite_friend_heading: formPayload.invite_friend_heading,
					inviteimage: formPayload.inviteimage,
					stroke_color: formPayload.stroke_color,
					company_consultant_fee: formPayload.company_consultant_fee,
					shopping_fee: formPayload.shopping_fee,
					plugin_size: formPayload.plugin_size,
					display_tab : formPayload.display_tab,
				};
	
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
			/* 	var domains = formPayload.domainSets
				for(let i in domains){
					formData.append('domainName[]',domains[i]['domain_name'])
				} */
				

				var domains = formPayload.domainSets;

				for(let i in domains){

					if(domains[i]['domain_id'] ==  'undefined'){
						formData.append('domainName['+ i + '][domain_id]','null')		
					}else if(domains[i]['domain_id'] !==  undefined && domains[i]['domain_id'] !==  ''){
						formData.append('domainName['+ i + '][domain_id]',domains[i]['domain_id'])
					}

					formData.append('domainName['+ i + '][domain_name]',domains[i]['domain_name'])
					formData.getAll('domainName');
				}
				this.props.getUpdateSetting(formData,config);
				////this.props.getUpdateSetting(qs.stringify(postObject));
			}
	}

	validateForm() {

		const {settings_commision_amount,location_distance,settings_id,settings_site_title,settings_from_name,settings_admin_email,settings_from_email,settings_company_address,settings_commission_percentage,settings_smtp_host,settings_smtp_user,settings_smtp_pass,settings_smtp_port,settings_username,settings_mobileno,settings_email_footer,settings_public_key,settings_product_key,setting_goal_amount,supporter_additional_fee,settings_secret_key,settings_embedcode,selectedFont,
			cancellation_fees_type,
			cancellation_hr,
			cancellation_fee,
			reschedule_fees_type,
			reschedule_fee,
			reschedule_hr,
			mailsend_doctor_time,basic_consultant_fee,consent_form_agree,chat_name,letter_spacing,selectedinvite_discount,invite_friend_description,invite_friend_short_description,invite_friend_heading,company_consultant_fee,shopping_fee
		} = this.state;
		let errors = 0;
      	//let formIsValid = true;
		if (!invite_friend_description) { 
			errors++;
			$('.error_invite_friend_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(invite_friend_description){		
			$('.error_invite_friend_description').html('');
		}
	
		if (!invite_friend_heading) { 
			errors++;
			$('.error_invite_friend_heading').html('<span class="errorspan">Please fill the field</span>');
		}else if(invite_friend_heading){		
			$('.error_invite_friend_heading').html('');
		}

		if (!settings_site_title) { 
			errors++;
			$('.errorsettings_site_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_site_title){		
			$('.errorsettings_site_title').html('');
		}
		
		if (!settings_from_name) { 
			errors++;
			$('.errorsettings_from_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_name){
			$('.errorsettings_from_name').html('');
		}

		if (!settings_admin_email) {
			errors++; 
			$('.errorsettings_admin_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_admin_email){
			$('.errorsettings_admin_email').html('');
		}
		if (!settings_from_email) {
			errors++; 
			$('.errorsettings_from_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_email){
			$('.errorsettings_from_email').html('');
		}
		if (!settings_username) { 
			errors++;
			$('.errorsettings_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_username){
			$('.errorsettings_username').html('');
		}

		if (!settings_mobileno) { 
			errors++;
			$('.errorsettings_mobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_mobileno){
			$('.errorsettings_mobileno').html('');
		} 


		if (!settings_commision_amount) { 
			errors++;
			$('.errorsettings_commision_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_commision_amount){
			$('.errorsettings_commision_amount').html('');
		}

		if (!settings_email_footer) { 
			errors++;
			$('.errorsettings_email_footer').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_email_footer){
			$('.errorsettings_email_footer').html('');
		}

		if(!cancellation_hr) { 
			errors++;
			$('.errorsettings_cancellation_hr').html('<span class="errorspan">Please fill the field</span>');
		}else if(cancellation_hr){
			$('.errorsettings_cancellation_hr').html('');
		}

		if(!cancellation_fee) { 
			errors++;
			$('.errorsettings_cancellation_fee').html('<span class="errorspan">Please fill the field</span>');
		}else if(cancellation_fee){
			$('.errorsettings_cancellation_fee').html('');
		}

		if(!reschedule_hr) { 
			errors++;
			$('.errorsettings_reschedule_hr').html('<span class="errorspan">Please fill the field</span>');
		}else if(reschedule_hr){
			$('.errorsettings_reschedule_hr').html('');
		}
		if(!mailsend_doctor_time) { 
			errors++;
			$('.errorsettings_mailsend_doctor_time').html('<span class="errorspan">Please fill the field</span>');
		}else if(mailsend_doctor_time){
			$('.errorsettings_mailsend_doctor_time').html('');
		}
		if(!basic_consultant_fee) { 
			errors++;
			$('.errorsettings_basic_consultant_fee').html('<span class="errorspan">Please fill the field</span>');
		}else if(basic_consultant_fee){
			$('.errorsettings_basic_consultant_fee').html('');
		}
		

		if(!reschedule_fee) { 
			errors++;
			$('.errorsettings_reschedule_fee').html('<span class="errorspan">Please fill the field</span>');
		}else if(reschedule_fee){
			$('.errorsettings_reschedule_fee').html('');
		}
		if(!location_distance) { 
			errors++;
			$('.errorsettings_location_distance').html('<span class="errorspan">Please fill the field</span>');
		}else if(location_distance){
			$('.errorsettings_location_distance').html('');
		}
		
		if(!company_consultant_fee){
			errors++;
			$('.errorsettings_company_consultant_fee').html('<span class="errorspan">Please fill the field</span>');
		}else if(company_consultant_fee){
			$('.errorsettings_company_consultant_fee').html('');
		}

		if(!shopping_fee){
			errors++;
			$('.errorsettings_shopping_fee').html('<span class="errorspan">Please fill the field</span>');
		}else if(shopping_fee){
			$('.errorsettings_shopping_fee').html('');
		}
		/*if (!settings_company_address) {
			errors++; console.log('settings_company_address');
			$('.errorsettings_company_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_company_address){
			$('.errorsettings_company_address').html('');
		}*/
		// if (!settings_commission_percentage) { console.log('settings_commission_percentage');
		// 	errors++;
		// 	$('.errorsettings_commission_percentage').html('<span class="errorspan">Please fill the field</span>');
		// }else if(settings_commission_percentage){
		// 	$('.errorsettings_commission_percentage').html('');
		// }

		/*if (!settings_smtp_user) {
			errors++;
			$('.errorsettings_smtp_user').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_user){
			$('.errorsettings_smtp_user').html('');
		}

		if (!settings_smtp_pass) {
			errors++;
			$('.errorsettings_smtp_pass').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_pass){
			$('.errorsettings_smtp_pass').html('');
		}

		if (!settings_smtp_host) {
			errors++;
			$('.errorsettings_smtp_host').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_host){
			$('.errorsettings_smtp_host').html('');
		}

		if (!settings_smtp_port) {
			errors++;
			$('.errorsettings_smtp_port').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_port){
			$('.errorsettings_smtp_port').html('');
		}*/
		if (!settings_public_key) {
			errors++;
			$('.errorsettings_public_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_public_key){
			$('.errorsettings_public_key').html('');
		}

		if (!settings_product_key) {
			errors++;
			$('.errorsettings_product_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_product_key){
			$('.errorsettings_product_key').html('');
		}
		
		if (!settings_secret_key) {
			errors++;
			$('.errorsettings_secret_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_secret_key){
			$('.errorsettings_secret_key').html('');
		}
		if (!consent_form_agree) {
			errors++;
			$('.error_consent').html('<span class="errorspan">Please fill the field</span>');
		}else if(consent_form_agree){
			$('.error_consent').html('');
		}
		if (!chat_name) {
			errors++;
			$('.errorsettings_chat_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(consent_form_agree){
			$('.errorsettings_chat_name').html('');
		}
		if (!letter_spacing) {
			errors++;
			$('.errorsettings_letter_spacing').html('<span class="errorspan">Please fill the field</span>');
		}else if(consent_form_agree){
			$('.errorsettings_letter_spacing').html('');
		}
		if (!selectedinvite_discount.value) {
			errors++;
			$('.errorsettings_discount').html('<span class="errorspan">Please select invite friends discount</span>');
		}else if(selectedinvite_discount.value){
			$('.errorsettings_discount').html('');
		}
		
	  
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){
		
    	if(Props.settings !== this.props.settings){
    		if(Object.keys(Props.settings).length > 0){
    			const formpayload = Props.settings[0].results;
				
    			this.setState({settings_id:formpayload.settings_id});
    			this.setState({settings_site_title:formpayload.settings_site_title});
    			this.setState({settings_from_name:formpayload.settings_from_name});
    			this.setState({settings_admin_email:formpayload.settings_admin_email});
    			this.setState({settings_from_email:formpayload.settings_from_email});
    			this.setState({settings_mailpath:formpayload.settings_mailpath});
    			this.setState({settings_company_address:formpayload.settings_company_address});
    			this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
    			this.setState({settings_smtp_host:formpayload.settings_smtp_host});
    			this.setState({settings_smtp_user:formpayload.settings_smtp_user});
    			this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
    			this.setState({settings_smtp_port:formpayload.settings_smtp_port});
    			this.setState({settings_username:formpayload.settings_username});
    			this.setState({settings_mobileno:formpayload.settings_mobileno});
    			this.setState({settings_email_footer:formpayload.settings_email_footer});
    			this.setState({hexColor:formpayload.settings_site_background_color});
    			this.setState({settings_public_key:formpayload.settings_public_key});
				this.setState({settings_product_key:formpayload.settings_product_key});
				this.setState({setting_goal_amount:formpayload.setting_goal_amount});
				this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
    			this.setState({settings_secret_key:formpayload.settings_secret_key});
				this.setState({settings_embedcode:formpayload.settings_embedcode});
				this.setState({settings_access_code:formpayload.access_code});				
				this.setState({count_domain:formpayload.count_domain});				
				this.setState({st_position_left:formpayload.st_position_left});
				this.setState({st_position_right:formpayload.st_position_right});
				this.setState({chat_name:formpayload.chat_name});
				this.setState({letter_spacing:formpayload.letter_spacing});
				this.setState({st_position_top:formpayload.st_position_top});
				this.setState({st_position_bottom:formpayload.st_position_bottom});
				this.setState({domainSets : formpayload.domainSets});
				
				this.setState({settings_commision_amount: formpayload.settings_commision_amount});
				this.setState({location_distance: formpayload.location_distance});
				this.setState({header_cat_limit: formpayload.header_cat_limit});
				this.setState({ai_fine_tune_model_id: formpayload.ai_fine_tune_model_id});
				this.setState({payment_form: formpayload.payment_form});
				this.setState({dashboard_view: formpayload.dashboard_view});
				this.setState({dashboard: formpayload.dashboard});
				this.setState({product_view_checkbox: formpayload.product_view_checkbox});
				this.setState({product_doctor_checkbox: formpayload.product_doctor_checkbox}); 
				this.setState({consent_form_agree: formpayload.consent_form});
				this.setState({reschedule_fee: formpayload.reschedule_fee});
				this.setState({reschedule_hr: formpayload.reschedule_hr});
				this.setState({mailsend_doctor_time: formpayload.mailsend_doctor_time});
				this.setState({basic_consultant_fee: formpayload.basic_consultant_fee});
				this.setState({np_theme: formpayload.notification_popup_theme});
				this.setState({cancellation_hr: formpayload.cancellation_hr});
				this.setState({cancellation_fee: formpayload.cancellation_fee});
				this.setState({cancellation_fees_type: formpayload.cancellation_fees_type});
				this.setState({aichat_type: formpayload.ai_model_type});
				this.setState({reschedule_fees_type: formpayload.reschedule_fees_type});
				this.setState({stroke_color: formpayload.canvas_stroke_clr});

				this.setState({company_consultant_fee: formpayload.company_consultant_fee,shopping_fee: formpayload.shopping_fee,plugin_size: formpayload.plugin_size,display_tab: formpayload.display_tab,});

				this.setState({invite_friend_short_description: formpayload.invite_friend_short_description});
				this.setState({invite_friend_description: formpayload.invite_friend_description});
				this.setState({invite_friend_heading: formpayload.invite_friend_heading});
				
				if(formpayload.settings_faq_user_model_id!== 'null' && formpayload.settings_faq_user_model_id !== ''){
					this.setState({selectedfaqmodeluser: {value:formpayload.settings_faq_user_model_id, label:formpayload.settings_faq_user_model_id},selectedfaqmodeluserval:formpayload.settings_faq_user_model_id });
				}
				if(formpayload.settings_faq_guest_model_id!== 'null' && formpayload.settings_faq_guest_model_id !== ''){
					this.setState({selectedfaqmodelguest: {value:formpayload.settings_faq_guest_model_id, label:formpayload.settings_faq_guest_model_id},selectedfaqmodelguestval:formpayload.settings_faq_guest_model_id});
				}
				if(formpayload.settings_faq_doctor_model_id!== 'null' && formpayload.settings_faq_doctor_model_id !== ''){
					this.setState({selectedfaqmodeldoctor: {value:formpayload.settings_faq_doctor_model_id, label:formpayload.settings_faq_doctor_model_id},selectedfaqmodeldoctorval:formpayload.settings_faq_doctor_model_id});
				}
				if(formpayload.settings_faq_company_model_id!== 'null' && formpayload.settings_faq_company_model_id !== ''){
					this.setState({selectedfaqmodelcompany: {value:formpayload.settings_faq_company_model_id, label:formpayload.settings_faq_company_model_id},selectedfaqmodelcompanyval:formpayload.settings_faq_company_model_id });
				}
				if(formpayload.settings_faq_technician_model_id!== 'null' && formpayload.settings_faq_technician_model_id !== ''){
					this.setState({selectedfaqmodeltechnician: {value:formpayload.settings_faq_technician_model_id, label:formpayload.settings_faq_technician_model_id},selectedfaqmodeltechnicianval:formpayload.settings_faq_technician_model_id });
				}
				if( formpayload.google_analystic_code !== 'null'){
					this.setState({google_analystic_code: formpayload.google_analystic_code});
				}else{
					this.setState({google_analystic_code: ''});
				}
				
    			if(formpayload.settings_mail_from_smtp === '1'){
    				this.setState({smtp_checked: true});
    			}else{
    				this.setState({smtp_checked: false});
    			}

    			if(formpayload.enable_live === '1'){
    				this.setState({stripe_live_checked: true,stripe_sandbox_checked:false });
    			}else{
    				this.setState({stripe_sandbox_checked: true,stripe_live_checked:false});
    			}

    			if(formpayload.st_plugin_position === 'fixed'){
    				this.setState({st_position_checked: true });
    			}else{
    				this.setState({st_position_checked: false});
    			}

    			if(formpayload.product_layout === '2'){
    				this.setState({product_layout2_checked: true });
    				this.setState({product_layout3_checked: false});
    			}else{
    				this.setState({product_layout3_checked: true});
    				this.setState({product_layout2_checked: false });
    			}

				if(formpayload.show_category_icons === 'yes'){
    				this.setState({category_icons_yes: true });
    				this.setState({category_icons_no: false});
    			}else{
    				this.setState({category_icons_no: true});
    				this.setState({category_icons_yes: false });
    			}
				if(formpayload.product_view === "0"){
    				this.setState({product_view_list: true });
    				this.setState({product_view_grid: false});
    			}else{
    				this.setState({product_view_grid: true});
    				this.setState({product_view_list: false });
    			}

				if(formpayload.dashboard === "list"){
    				this.setState({dashboard_view_list: true });
    				this.setState({dashboard_view_grid: false});
    			}else{
    				this.setState({dashboard_view_grid: true});
    				this.setState({dashboard_view_list: false });
    			}

				if(formpayload.dashbaord_template == 1){
    				this.setState({selected_templateval:1,selected_template:{value:1,label:'Template 1'} });
    			}else if(formpayload.dashbaord_template == 2){
    				this.setState({selected_templateval:2,selected_template:{value:1,label:'Template 2'} });
    			}else{
					this.setState({selected_templateval:3,selected_template:{value:1,label:'Template 3'} });
				}

			    if(Props.settings[0].font!== '' && Props.settings[0].font!== null){
			        this.setselectedvalue(Props.settings[0].font);
			    }

				if(Props.settings[0].invite_friends_discount!== '' && Props.settings[0].invite_friends_discount!== null){
					this.setState({selectedinvite_discount:{ value: Props.settings[0].results.invite_friends_discount, label: Props.settings[0].results.invite_friends_discount }});
			    }

				if(Props.settings[0].discountlist!== '' && Props.settings[0].discountlist!== null){
					this.setState({invite_discount_list:Props.settings[0].discountlist});
			    }
				if(Props.settings[0].results.catcanvas_image!== '' && Props.settings[0].results.catcanvas_image!== null){
					this.setState({catimage_preview:mediaUrl+Props.settings[0].results.catcanvas_image});
			    }
				if(Props.settings[0].results.dogcanvas_image!== '' && Props.settings[0].results.dogcanvas_image!== null){
					this.setState({dogimage_preview:mediaUrl+Props.settings[0].results.dogcanvas_image});
			    }
				if(Props.settings[0].results.inviteimage!== '' && Props.settings[0].results.inviteimage!== null){
					this.setState({inviteimage_preview:mediaUrl+Props.settings[0].results.inviteimage});
			    }


				


    		}
    	}
    	  	if(Props.updatesetting !== this.props.updatesetting){ 
			
    		if(Object.keys(Props.updatesetting).length > 0){

				if(Props.updatesetting[0].status=='catcanvaserror'){

					$('.catimage_success').html(Props.updatesetting[0].message);
						// setTimeout(
						// function() {
						// 	$('.female_image_success').html('');
						// }
						// .bind(this),
						// 3000
						// );
				}else if(Props.updatesetting[0].status=='dogcanvaserror'){
					$('.dogimage_success').html(Props.updatesetting[0].message);
						// setTimeout(
						// function() {
						// 	$('.image_success').html('');
						// }
						// .bind(this),
						// 3000
						// );
				}else{	
					this.setState({ Loading: false });
					const formpayload = Props.updatesetting[0].results;					
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updatesetting[0].message+'</h3></div>');
					this.setState({settings_id:formpayload.settings_id});
					this.setState({settings_site_title:formpayload.settings_site_title});
					this.setState({settings_from_name:formpayload.settings_from_name});
					this.setState({settings_admin_email:formpayload.settings_admin_email});
					this.setState({settings_from_email:formpayload.settings_from_email});
					this.setState({settings_mailpath:formpayload.settings_mailpath});
					this.setState({settings_company_address:formpayload.settings_company_address});
					this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
					this.setState({settings_smtp_host:formpayload.settings_smtp_host});
					this.setState({settings_smtp_user:formpayload.settings_smtp_user});
					this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
					this.setState({settings_smtp_port:formpayload.settings_smtp_port});
					this.setState({settings_username:formpayload.settings_username});
					this.setState({settings_mobileno:formpayload.settings_mobileno});
					this.setState({settings_email_footer:formpayload.settings_email_footer});
					this.setState({hexColor:formpayload.settings_site_background_color});
					this.setState({settings_public_key:formpayload.settings_public_key});
					this.setState({settings_product_key:formpayload.settings_product_key});
					this.setState({setting_goal_amount:formpayload.setting_goal_amount});
					this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
					this.setState({settings_secret_key:formpayload.settings_secret_key});
					this.setState({settings_embedcode:formpayload.settings_embedcode});
					this.setState({settings_access_code:formpayload.access_code});
					this.setState({count_domain:formpayload.count_domain});
					this.setState({st_position_left:formpayload.st_position_left});
					this.setState({st_position_right:formpayload.st_position_right});
					this.setState({st_position_top:formpayload.st_position_top});
					this.setState({st_position_bottom:formpayload.st_position_bottom});
					this.setState({domainSets: formpayload.domainSets});
					this.setState({settings_commision_amount: formpayload.settings_commision_amount});
					this.setState({location_distance: formpayload.location_distance});
					this.setState({header_cat_limit: formpayload.header_cat_limit});
					this.setState({ai_fine_tune_model_id: formpayload.ai_fine_tune_model_id});
					this.setState({chat_name: formpayload.chat_name});
					this.setState({letter_spacing: formpayload.letter_spacing});	
					this.setState({stroke_color: formpayload.canvas_stroke_clr});			
					this.setState({np_theme: formpayload.notification_popup_theme});
					
					this.setState({company_consultant_fee: formpayload.company_consultant_fee,shopping_fee: formpayload.shopping_fee,plugin_size: formpayload.plugin_size,});

					if( formpayload.google_analystic_code !== 'null'){
						this.setState({google_analystic_code: formpayload.google_analystic_code});
					}else{
						this.setState({google_analystic_code: ''});
					}

					if(formpayload.settings_mail_from_smtp === '1'){
						this.setState({smtp_checked: true});
					}else{
						this.setState({smtp_checked: false});
					}


					if(formpayload.st_plugin_position === 'fixed'){
						this.setState({st_position_checked: true });
					}else{
						this.setState({st_position_checked: false});
					}

					if(formpayload.product_layout === '2'){
						this.setState({product_layout2_checked: true });
						this.setState({product_layout3_checked: false});
					}else{
						this.setState({product_layout3_checked: true});
						this.setState({product_layout2_checked: false });
					}

					if(formpayload.enable_live === '1'){
						this.setState({stripe_checked: true});
					}else{
						this.setState({stripe_checked: false});
					}

					if(formpayload.show_category_icons === 'yes'){
						this.setState({category_icons_yes: true });
						this.setState({category_icons_no: false});
					}else{
						this.setState({category_icons_no: true});
						this.setState({category_icons_yes: false });
					}
					if(formpayload.product_view === "0"){
						this.setState({product_view_list: true });
						this.setState({product_view_grid: false});
					}else{
						this.setState({product_view_grid: true});
						this.setState({product_view_list: false });
					}
    				scrollToTop();

    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
				}
    		}
    	}
    	/*if(Props.fontname !== this.props.fontname){
    		if(Object.keys(Props.fontname).length > 0){
    		    this.setState({ fonttype : Props.fontname[0].fontlist})

    		}
    	}*/

    	var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'font/getfont_name?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						fonttype: res.data.fontlist
					})
				
				}else{
				//console.log("test")
				}
			});

	}

	setselectedvalue(selectsers){
		
	    const fontArray = selectsers.map((fonttype, index) => ({
	     id: index,
	     name: fonttype,
	    }));
    // Dynamically create select list
    let fonts = [];
    fontArray.map(item =>
    fonts.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedFont : fonts})
  }

  	setselecteddiscount(selectsers){
		const fontArray = selectsers.map((fonttype, index) => ({
		id: index,
		name: fonttype,
		}));
		// Dynamically create select list
		let fonts = [];
		fontArray.map(item =>
		fonts.push({ label: item.name.label, value: item.name.value }),
		);
		this.setState({selectedFont : fonts})
	}


	handleClick = () => {
	this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
	this.setState({ displayColorPicker: false })
	};

	handleChange = (color) => {
	this.setState({hexColor : color.hex})
	this.setState({ color: color.rgb })
	};

	 copyCodeToClipboard(event){
		event.preventDefault;
		const el = this.textArea
		el.select()
		document.execCommand("copy")
   }


   writeText = () => {
	 
	$('.copied_title').html('<span class=""><h3>Copied</h3></span>');

	//onClick={() =>  navigator.clipboard.writeText(tester)}
	let test1 = this.state.settings_access_code;
	
	let  tester =	'<script src="https://runadmin.UXT.design/plugin.js"></script> <div data-client="'+test1+'"  id="pet_container"></div>';
	 navigator.clipboard.writeText(tester);
	setTimeout(
		function() {
			$('.copied_title').html('');
			$('.copied_title').hide();
		}
		.bind(this),
		3000
		);
		
    }

/*    myCallback = (domainsets) => {
   	//console.log(domainsets,'domainsets')
    this.setState({domainSets: domainsets});
  }; */

  myCallback = (domainsets,domainID,domain_count) => {

    this.setState({domainSets: domainsets,removeID: domainID,domain_count:domain_count});
   };


   handleChangeFont = selectedFont => {
      this.setState({ selectedFont});
      this.setState({ fontvalue : selectedFont.value });
      if(selectedFont.value === ''){
        //$('.errorfont').html('<span class="errorspan">Please select the font</span>');
      }else{
      	 $('.errorfont').html('');
      }
   }
    handleChangeDiscount = selectedinvite_discount => {
	this.setState({ selectedinvite_discount});
	this.setState({ discountvalue : selectedinvite_discount.value });
	if(selectedinvite_discount.value === ''){
	  
	}else{
		$('.errorsettings_discount').html('');
	}
 }
   handleChangeAimodeluser = selectedfaqmodeluser => {
	this.setState({ selectedfaqmodeluser});
	this.setState({ selectedfaqmodeluserval : selectedfaqmodeluser.value });	
 }
handleChangeAimodelguest = selectedfaqmodelguest => {
	this.setState({ selectedfaqmodelguest});
	this.setState({ selectedfaqmodelguestval : selectedfaqmodelguest.value });	
}
handleChangeAimodeldoctor = selectedfaqmodeldoctor => {
	this.setState({ selectedfaqmodeldoctor});
	this.setState({ selectedfaqmodeldoctorval : selectedfaqmodeldoctor.value });	
}

handleChangeAimodelcompany = selectedfaqmodelcompany => {
	this.setState({ selectedfaqmodelcompany});
	this.setState({ selectedfaqmodelcompanyval : selectedfaqmodelcompany.value });	
}
handleChangeAimodeltechnician = selectedfaqmodeltechnician => {
	this.setState({ selectedfaqmodeltechnician});
	this.setState({ selectedfaqmodeltechnicianval : selectedfaqmodeltechnician.value });	
}
handleChangeDahboardTemplate = selected_template =>{
	this.setState({ selected_template});
	this.setState({ selected_templateval : selected_template.value });
}
showTemplatePopup = ()=>{
	$('.template-popup').addClass('show');
}
closeTemplatePopup  = ()=>{
	$('.template-popup').removeClass('show');
}

onEditorAIChange( evt ) {

	var callfunc = this;

	setTimeout(function () {

	 this.setState( {

		 ck_description: evt

	 },()=>{

		

	 } );



	 }.bind(this),1000);

 

}

  render() {
  	const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${ this.state.hexColor }`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
	let test1 = this.state.settings_access_code;
	//let test = Number(this.state.settings_access_code);
	
	const scripttype = '';
	
		/*const tester =	'<!-- Include <div> tag after the script -->\n<script src="http://car-c.uxt.design/plugin.js"></script>\n<div data-client="'+test+'"  id="car_container"></div>';
		*/
		
		
	let  tester =	'<script src="https://runadmin.UXT.design/plugin.js"></script> <div data-client="'+test1+'"  id="pet_container"></div>';


	const fontArray = this.state.fonttype.map ((fonttype, index) => ({
	id: index,
	name: fonttype,
	}));
	// Dynamically create select list
	let fonts = [];
	fontArray.map(item =>
	fonts.push({ label: item.name.label, value: item.name.value }),
	);

	const {selectedFont,selectedfaqmodeluser,selectedfaqmodelguest ,selectedfaqmodeldoctor,selectedfaqmodeltechnician,selectedfaqmodelcompany,selectedinvite_discount,invite_discount_list,selected_template} = this.state;
	const style_text = {
	color: 'white',
    backgroundColor: '#000',
    padding: '9px 9px',
    minwidth: '49px',
    height: '37px',
	textalign:'center',
    texttransform: 'capitalize'
}

if(this.state.cancellation_fees_type == 'flat')
	{
		$('.cancellation_fee').html('Cancellation fees ($):')
		
	}else{
		$('.cancellation_fee').html('Cancellation fees (%):')
	}	
	if(this.state.reschedule_fees_type == 'flat')
	{
		$('.reschedule_fee').html('Reschedule fees ($):')
		
	}else{
		$('.reschedule_fee').html('Reschedule fees (%):')
	}	

	
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="setting" />		

	
	<div className="content">	
		<div className="content-wrapper">
		  <div class="content-wrapper-before"></div>
			<div className="content-body setting-main-head leaa-admin-head card">
			<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Settings</h4>
			</div>
			<div className="form-row">
			<div className="form-left">

		

				<div className="form-group">
				{localStorage.getItem('admin_id') == 1 ?<label>Site Name:</label>:<label>Company Name<span class="required" style={{ color: "red" }} > * </span>
:</label>}
					
					<input type="text" name="settings_site_title" onChange={this.handleInputChange} className="form-control" value={this.state.settings_site_title} />
					<div className="errorsettings_site_title"></div>
				</div>
				<div className="form-group">					
					<label>From Name<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="text" name="settings_from_name" onChange={this.handleInputChange} className="form-control"value={this.state.settings_from_name} />
					<div className="errorsettings_from_name"></div>
				</div>
				<div className="form-group">
					<label>From Email<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="text" name="settings_from_email"  onChange={this.handleInputChange} className="form-control" value={this.state.settings_from_email} />
					<div className="errorsettings_from_email"></div>
				</div>
			
				

				<div className="default-font">
			  <div className="form-group">
					<label>Font:</label>
				     <Select 
                         options={fonts}  
                         value={selectedFont?selectedFont:{ value: '0', label: 'Select Font Type' }}
                         onChange={this.handleChangeFont}
                         placeholder="Select Font Type" />
					<div className="errorfont"></div>
				</div>
				</div> 

				<div className="form-group">
					<label>Dog 3D Model:</label>
					<div className="choose-file">
					<div className="dogimage_success"></div>
					<span className="profile_btn"><input type="file" name="dogimage" onChange={this.onChangeHandlerDog} /></span>
					</div>
					{this.state.dogimage_preview != '' ? <img src={this.state.dogimage_preview} width={100} height={100} />:''}
					
				</div>
				
			
			</div>
			<div className="form-right">

				<div className="form-group">					
					{localStorage.getItem('admin_id') == 1 ? <label>Admin Email<span class="required" style={{ color: "red" }} > * </span>
						:</label>:<label>Company Email<span class="required" style={{ color: "red" }} > * </span>
						:</label>} 
					<input type="text" className="form-control" onChange={this.handleInputChange} name="settings_admin_email" value={this.state.settings_admin_email} />
					<div className="errorsettings_admin_email"></div>
				</div>
				
				<div className="form-group">
					<label>Contact No<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="text" className="form-control" name="settings_mobileno"  onChange={this.handleInputChange} value={this.state.settings_mobileno}/>
					<div className="errorsettings_mobileno"></div>
				</div>

				<div className="form-group"> 
					<label>Email Footer Content<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="text" className="form-control" name="settings_email_footer" onChange={this.handleInputChange} value={this.state.settings_email_footer}/>
					<div className="errorsettings_email_footer"></div>
				</div>
				<div className="form-group">
					<label>Stroke Color:</label><br></br>
					<input type="color" id="head" name="stroke_color" value={this.state.stroke_color} onChange={this.handleInputChange} />
            	</div>
				<div className="form-group">
					<label>Cat 3D Model:</label>
					<div className="choose-file">
					<div className="catimage_success"></div>
					<span className="profile_btn"><input type="file" name="catimage" onChange={this.onChangeHandlerCat} /></span>
					</div>
					{this.state.catimage_preview != '' ? <img src={this.state.catimage_preview} width={100} height={100} />:''}
					
				</div>

				


				{/* { localStorage.getItem('admin_id') == 1 ? <div className="form-group site-bg-clr"> 
				<label>Site Background Color: </label>
						<div style={ styles.swatch } onClick={ this.handleClick }>
						<div style={ styles.color } />
						</div>
						{ this.state.displayColorPicker ? <div style={ styles.popover }>
						<div style={ styles.cover } onClick={ this.handleClose }/>
						<SketchPicker color={ this.state.color } onChange={ this.handleChange } />
						</div> : null }
				</div> : '' } */}

				  
			</div>				
			</div>	
			{/* -------------invite friend content start-------------- */}

			<div className="title">
				<h4>Invite Friend Setting</h4>
		   </div> 

			<div className="form-row">
			<div className="form-left">
				<div className='form-group'>
					<label> Heading </label>

					<MyEditor id="aifeed_desc" initialData={this.state.invite_friend_heading} onEditorChange={this.onEditorChangeinvitefrndHead}> </MyEditor>


					{/* <CKEditor
						data={this.state.invite_friend_heading}
						//data={templatecontent}
						onChange={this.onEditorChangeinvitefrndHead}
						type="classic"
					/>  */}


					<div className="error_invite_friend_heading"></div>
				</div>
				<div className='form-group'>
					<label> Short Description </label>

					<MyEditor2 id="aifeed_desc" initialData={this.state.invite_friend_short_description} onEditorChange={this.onEditorChangeinvitefrndShortDesc}> </MyEditor2>

					{/* <CKEditor
						data={this.state.invite_friend_short_description}
						//data={templatecontent}
						onChange={this.onEditorChangeinvitefrndShortDesc}
						type="classic"
					/>  */}
					<div className="error_invite_friend_short_description"></div>
				</div>
			</div> 
			<div className="form-right">
			<div className='form-group'>
					<label>Description </label>
					<MyEditor1 id="aifeed_desc" initialData={this.state.invite_friend_description} onEditorChange={this.onEditorChangeinvitefrndDesc}> </MyEditor1>
					{/* <CKEditor
						data={this.state.invite_friend_description}
						//data={templatecontent}
						onChange={this.onEditorChangeinvitefrndDesc}
						type="classic"
					/>  */}
					<div className="error_invite_friend_description"></div>
				</div>
				<div className="form-group">
					<label>Image:</label>
					<div className="choose-file">
					<div className="inviteimage_success"></div>
					<span className="profile_btn"><input type="file" name="inviteimage" onChange={this.onChangeHandlerinviteimage} /></span>
					</div>
					{this.state.inviteimage_preview != '' ? <img src={this.state.inviteimage_preview} width={100} height={100} />:''}
				</div>			
			</div>
      	</div>	
		{/* -------------invite friend content setting end-------------- */}
			{/* -------------chat ai setting start-------------- */}

			<div className="title">
				<h4>Chat AI Setting</h4>
		   </div> 

			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
			<label>Chat AI type: </label>
				
				</div>
				<div className="form-group">
					<label>Common /Guest Conversation</label>					
				</div>

				<div className="form-group">
					<label>User Conversation</label>					
				</div>

				<div className="form-group">
					<label>Vet / Provider Conversation</label>					
				</div>
				
				<div className="form-group">
					<label>Technician Conversation</label>					
				</div>
				<div className="form-group">
					<label>Company Conversation</label>					
				</div>
			</div> 
			<div className="form-right">
			<div className="form-group">
						
						<input onChange={this.handleInputChange} type="radio" name="aichat_type" checked={this.state.aichat_type=='text'?'chekced':''} value="text"/>
						<span>Text</span>
							<input onChange={this.handleInputChange} type="radio"  name="aichat_type" checked={this.state.aichat_type=='chat'?'chekced':''} value="chat"/>
						<span>Chat </span>
					</div> 
			{/* faqmodelguest: '',
			faqmodeluser: '',
			faqmodeldoctor: '',
			faqmodelcompany:'', */}
			
			<div className="form-group">					
				{/* <label>Guest Caht Ai model:</label> */}
				
				<Select 
					options={this.state.faqmodelguest}  
					value={selectedfaqmodelguest?selectedfaqmodelguest:{ value: '0', label: 'Select guest ai model' }}
					onChange={this.handleChangeAimodelguest}
					placeholder="Select AI Model ID" 
				/>			
			</div>

			<div className="form-group">					
				{/* <label>User Caht Ai model:</label>			 */}
				<Select 
					options={this.state.faqmodeluser}  
					value={selectedfaqmodeluser?selectedfaqmodeluser:{ value: '0', label: 'Select user ai model' }}
					onChange={this.handleChangeAimodeluser}
					placeholder="Select AI Model ID" 
				/>			
			</div>			

			<div className="form-group">					
				{/* <label>Doctor Caht Ai model:</label>			 */}
				<Select 
					options={this.state.faqmodeldoctor}  
					value={selectedfaqmodeldoctor?selectedfaqmodeldoctor:{ value: '0', label: 'Select vet ai model' }}
					onChange={this.handleChangeAimodeldoctor}
					placeholder="Select AI Model ID" 
				/>			
			</div>	
			<div className="form-group">					
				{/* <label>Company Caht Ai model:</label>			 */}
				<Select 
					options={this.state.faqmodeltechnician}  
					value={selectedfaqmodeltechnician?selectedfaqmodeltechnician:{ value: '0', label: 'Select technician ai model' }}
					onChange={this.handleChangeAimodeltechnician}
					placeholder="Select AI Model ID" 
				/>			
			</div>	
			<div className="form-group">					
				{/* <label>Company Caht Ai model:</label>			 */}
				<Select  
					options={this.state.faqmodelcompany}  
					value={selectedfaqmodelcompany?selectedfaqmodelcompany:{ value: '0', label: 'select company ai model' }}
					onChange={this.handleChangeAimodelcompany}
					placeholder="Select AI Model ID" 
				/>			
			</div>	

			
			</div>
      	</div>	
		{/* -------------chat ai setting end-------------- */}
			<div className="title">
				<h4>Appointment Settings</h4>
			</div>  
			<div className="form-row">

			


				<div className="form-left">
					<div className="form-group"> 
						<label className='location_distance'>Doctor Location Distance(mi)<span class="required" style={{ color: "red" }} > * </span>
						:</label>
						<input type="text" className="form-control" name="location_distance" onChange={this.handleInputChange} value={this.state.location_distance}/>
						<div className="errorsettings_location_distance"></div>
					</div>
					<div className="form-group">
						<label>Cancellation fees type: </label>
						<br></br>
						<input onChange={this.handleInputChange} type="radio" name="cancellation_fees_type" checked={this.state.cancellation_fees_type=='flat'?'chekced':''} value="flat"/>
						<span>Flat Amount </span>
							<input onChange={this.handleInputChange} type="radio"  name="cancellation_fees_type" checked={this.state.cancellation_fees_type=='percentage'?'chekced':''} value="percentage"/>
						<span>Percentage </span>
					</div> 
					<div className="form-group"> 
						<label className='cancellation_fee'>Cancellation fees<span class="required" style={{ color: "red" }} > * </span>
:</label>
						<input type="text" className="form-control" name="cancellation_fee" onChange={this.handleInputChange} value={this.state.cancellation_fee}/>
						<div className="errorsettings_cancellation_fee"></div>
					</div>

					<div className="form-group"> 
						<label>Cancellation Hours<span class="required" style={{ color: "red" }} > * </span>
:</label>
						<input type="text" className="form-control" name="cancellation_hr" onChange={this.handleInputChange} value={this.state.cancellation_hr}/>
						<div className="errorsettings_cancellation_hr"></div>
					</div>
					<div className='form-group'>
						<label> Consent Form</label>
						<MyEditor3 id="aifeed_desc" initialData={this.state.consent_form_agree} onEditorChange={this.onEditorChange}> </MyEditor3>
						{/* <CKEditor
							data={this.state.consent_form_agree}
							//data={templatecontent}
							onChange={this.onEditorChange}
							type="classic"
						/>  */}

						{/* <textarea type="text" rows="7" name="consent_form_agree" onChange={this.handleInputChange} className="form-control" value={this.state.consent_form_agree} /> */}
						<div className="error_consent"></div>
					</div>
				
				</div>

				<div className="form-right">
					<div className="form-group">
						<label>Reschedule fees type: </label>
						<br></br>
						<input onChange={this.handleInputChange} type="radio" name="reschedule_fees_type" checked={this.state.reschedule_fees_type=='flat'?'chekced':''} value="flat"/>
						<span>Flat Amount</span>
							<input onChange={this.handleInputChange} type="radio"  name="reschedule_fees_type" checked={this.state.reschedule_fees_type=='percentage'?'chekced':''} value="percentage" />
						<span>Percentage</span>
					</div> 
					<div className="form-group"> 
						<label className='reschedule_fee'>Reschedule fees<span class="required" style={{ color: "red" }} > * </span>
						:</label>
						<input type="text" className="form-control" name="reschedule_fee" onChange={this.handleInputChange} value={this.state.reschedule_fee}/>
						<div className="errorsettings_reschedule_fee"></div>
					</div>
					<div className="form-group"> 
						<label>Reschedule Hours<span class="required" style={{ color: "red" }} > * </span>
						:</label>
						<input type="text" className="form-control" name="reschedule_hr" onChange={this.handleInputChange} value={this.state.reschedule_hr}/>
						<div className="errorsettings_reschedule_hr"></div>
					</div>

					<div className="form-group"> 
						<label>Mail Send Time<span class="required" style={{ color: "red" }} > * </span>
						:</label>
						<input type="text" className="form-control" name="mailsend_doctor_time" onChange={this.handleInputChange} value={this.state.mailsend_doctor_time}/>
						<div className="errorsettings_mailsend_doctor_time"></div>
					</div>	

					<div className="form-group"> 
						<label>Vet consultant fees($)<span class="required" style={{ color: "red" }} > * </span>
						:</label>
						<input type="text" className="form-control" name="basic_consultant_fee" onChange={this.handleInputChange} value={this.state.basic_consultant_fee}/>
						<div className="errorsettings_basic_consultant_fee"></div>
					</div>

					<div className="form-group"> 
						<label>Company Booking Fee<span class="required" style={{ color: "red" }} > * </span>
						:</label>
						<input type="text" className="form-control" name="company_consultant_fee" onChange={this.handleInputChange} value={this.state.company_consultant_fee}/>
						<div className="errorsettings_company_consultant_fee"></div>
					</div>
					<div className="form-group"> 
						<label>Shopping Commission % <span class="required" style={{ color: "red" }} > * </span>
						:</label>
						<input type="text" className="form-control" name="shopping_fee" onChange={this.handleInputChange} value={this.state.shopping_fee}/>
						<div className="errorsettings_shopping_fee"></div>
					</div>

				</div>
			</div>
		    <div className="title">
				<h4>Mail Configuration Settings</h4>
			</div> 
			
			 <div className="form-group">
				    <input onChange={this.handleInputChange} type="checkbox"  name="settings_mail_from_smtp" checked={this.state.smtp_checked}/>
	                <span>Send Mail From Smtp</span>
            </div> 

		 <div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Smtp Host:</label>
					<input type="text" className="form-control" name="settings_smtp_host" onChange={this.handleInputChange}  value={(this.state.settings_smtp_host != 'null')?this.state.settings_smtp_host:''}/>
					<div className="errorsettings_smtp_host"></div>
				</div>
				<div className="form-group">					
					<label>Smtp Username:</label>
					<input type="text" className="form-control" name="settings_smtp_user"  onChange={this.handleInputChange}  value={(this.state.settings_smtp_user != 'null')?this.state.settings_smtp_user:''} />
					<div className="errorsettings_smtp_user"></div>
				</div>					
				<div className="form-group">
					<label>Smtp Password:</label>
					<input type="password" className="form-control" name="settings_smtp_pass" onChange={this.handleInputChange}  value={(this.state.settings_smtp_pass != 'null')?this.state.settings_smtp_pass:''} />
				<div className="errorsettings_smtp_pass"></div>
				</div>
			</div>			
			<div className="form-right">
				<div className="form-group">					
					<label>Smtp Port:</label>
					<input type="text" className="form-control"  name="settings_smtp_port" onChange={this.handleInputChange}  value={(this.state.settings_smtp_port != 'null')?this.state.settings_smtp_port:''} />
				<div className="errorsettings_smtp_port"></div>
				</div>
				<div className="form-group"> 
					<label>Mail Path:</label>
					<input type="text" className="form-control" name="settings_mailpath" onChange={this.handleInputChange} placeholder=""  value={(this.state.settings_mailpath != 'null')?this.state.settings_mailpath:''}/>
				</div>
			</div>	
			</div> 
			
			<div className="title">
				<h4>Commision Settings</h4>
			</div>  

			
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Commision Percentage %<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="text" className="form-control" name="settings_commision_amount" onChange={this.handleInputChange}  value={(this.state.settings_commision_amount != 'null')?this.state.settings_commision_amount:''}/>
					<div className="errorsettings_commision_amount"></div>
				</div>
				</div>
				</div> 

				

		<div className="title">
				<h4>Domain & Embed Code</h4>
			</div> 
		<div className="form-row">
				<div className="form-left">
				<div className="form-group">
						<label>Embed Code:</label>
						<p className="right-side-header"  id="embed_code" name="settings_embedcode" value={tester} title="Copy Text" >{tester}</p>
						<br></br>
						<a id='copytext' style={style_text} onClick={this.writeText}   >Copy  </a> <br></br>
						<div><br></br><div className="copied_title"></div></div>
						<div className="errorsettings_embedcode_title"></div>
					</div>
					
					<div className='form-group'>
				     <h4>Google Analytics code </h4>
					 <textarea type="text" name="google_analystic_code" onChange={this.handleInputChange} className="form-control" value={this.state.google_analystic_code} />
					</div>
				</div>	
				<div className="form-right">

				

				    <div className='form-group'>
				     <h4>Domain </h4>
				    <div className="domain-url">
					<Addinputsimple myCallback={this.myCallback} domainname={this.state.domainSets} count_domain={this.state.count_domain}/>
					</div>
					</div>
				
				</div>				
			</div>
			 <h5>Plugin Position (Numbers Only)</h5> 
			 <div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						<label>Left : %</label>
						<input type="number" className="form-control" name="st_position_left"  onChange={this.handleInputChange} value={this.state.st_position_left}/>
						<div className="errorsettings_st_position_left"></div>
					</div>
					<div className="form-group">
						<label>Right : %</label>
						<input type="number" className="form-control" name="st_position_right"  onChange={this.handleInputChange} value={this.state.st_position_right}/>
						<div className="errorsettings_st_position_right"></div>
					</div>
					<div className="form-group">
						<label>Position : </label>
						<input onChange={this.handleInputChange} type="checkbox"  name="st_position" checked={this.state.st_position_checked}/>
	                    <span>Fixed</span>
					</div>
					<div className="form-group">
						<label>Show category icons: </label>
					    <input onChange={this.handleInputChange} type="radio" name="category_icons" checked={this.state.category_icons_yes}/>
		                <span>Yes</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="category_icons" checked={this.state.category_icons_no}/>
		                <span>No</span>
 					</div> 
					
					 <div className="form-group">
						<label>Product View: </label>
					    <input onChange={this.handleInputChange} type="radio" name="product_view" checked={this.state.product_view_list}/>
		                <span>List View</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="product_view" checked={this.state.product_view_grid}/>
		                <span>Grid View</span>
 					</div> 
					 <div className="form-group">
						<label>Payment Form:</label><br></br>
							<input onChange={this.handleInputChange} type="radio" name="payment_form" checked={this.state.payment_form=='normal'?'chekced':''} value="normal"/>
						<span>Normal</span>
						<input onChange={this.handleInputChange} type="radio"  name="payment_form" checked={this.state.payment_form=='widget'?'chekced':''} value="widget"/>
						<span>Widget</span>
					</div> 

					<div className="form-group">
						<label>AI Fine Tune model ID: </label>
						<input type="text" className="form-control" name="ai_fine_tune_model_id"  onChange={this.handleInputChange} value={this.state.ai_fine_tune_model_id}/>
						{/* <div className="errorsettings_st_position_bottom"></div> */}
					</div>
					<div className="form-group">
						<label>Chat name: </label>
						<input type="text" className="form-control" name="chat_name"  onChange={this.handleInputChange} value={this.state.chat_name}/>
						<div className="errorsettings_chat_name"></div>
					</div>
					
					<div className="default-font">
						<div className="form-group">
							<label>Invite friends discount:</label>
							<Select 
								options={invite_discount_list}  
								value={selectedinvite_discount?selectedinvite_discount:{ value: '0', label: 'Select Invite Discount' }}
								onChange={this.handleChangeDiscount}
								placeholder="Select Font Type" />
							<div className="errorsettings_discount"></div>
						</div>
					</div> 
					<div className="form-group">
						<label>Letter spacing: </label>
						<input type="text" className="form-control" name="letter_spacing"  onChange={this.handleInputChange} value={this.state.letter_spacing}/>
						<div className="errorsettings_letter_spacing"></div>
					</div>
				</div>
				<div className="form-right">
					<div className="form-group">
						<label>Top: %</label>
						<input type="number" className="form-control" name="st_position_top"  onChange={this.handleInputChange} value={this.state.st_position_top}/>
						<div className="errorsettings_st_position_top"></div>
					</div>
					<div className="form-group">
						<label>Bottom: %</label>
						<input type="number" className="form-control" name="st_position_bottom"  onChange={this.handleInputChange} value={this.state.st_position_bottom}/>
						<div className="errorsettings_st_position_bottom"></div>
					</div>

					<div className="form-group">
						<label>Product Layout: </label>
					    <input onChange={this.handleInputChange} type="radio" name="product_layout" checked={this.state.product_layout2_checked}/>
		                <span>2 Layout</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="product_layout" checked={this.state.product_layout3_checked}/>
		                <span>3 Layout</span>
 					</div> 

					 <div className="form-group">
						<label>Header category limit: </label>
						<input type="number" className="form-control" name="header_cat_limit"  onChange={this.handleInputChange} value={this.state.header_cat_limit}/>
						{/* <div className="errorsettings_st_position_bottom"></div> */}
					</div>
					<div className="form-group">
						<label>Notification Popup Theme: </label>
						<br/>
					    <input onChange={this.handleInputChange} type="radio" name="np_theme" value="Black" checked={(this.state.np_theme == "Black" ? "checked" : "" )}/>
		                <span>Black bg with White text</span>
						<br/>
		                 <input onChange={this.handleInputChange} type="radio"  name="np_theme" value="White" checked={(this.state.np_theme == "White" ? "checked" : "" )}/>
		                <span>White bg with Black text</span>
 					</div>
					 <div className="form-group">
						<label>Plugin Size: </label>
						<br/>
					    <input onChange={this.handleInputChange} type="radio" name="plugin_size" value="600" checked={(this.state.plugin_size == "600" ? "checked" : "" )}/>
		                <span>600px</span>
						
		                 <input onChange={this.handleInputChange} type="radio"  name="plugin_size" value="700" checked={(this.state.plugin_size == "700" ? "checked" : "" )}/>
		                <span>700px</span>
						{/* <input onChange={this.handleInputChange} type="radio"  name="plugin_size" value="900" checked={(this.state.plugin_size == "900" ? "checked" : "" )}/>
		                <span>900px</span> */}
 					</div>

					 <div className="form-group">
						<label>Show Product checkbox for user:</label><br></br>
							<input onChange={this.handleInputChange} type="radio" name="product_view_checkbox" checked={this.state.product_view_checkbox=='show'?'chekced':''} value="show"/>
						<span>Show</span>
						<input onChange={this.handleInputChange} type="radio"  name="product_view_checkbox" checked={this.state.product_view_checkbox=='hide'?'chekced':''} value="hide"/>
						<span>Hide</span>
					</div> 

					<div className="form-group">
						<label>Display Tab:</label><br></br>
							<input onChange={this.handleInputChange} type="radio" name="display_tab" checked={this.state.display_tab=='adsTab'?'chekced':''} value="adsTab"/>
						<span>Advertisement</span>
						<input onChange={this.handleInputChange} type="radio"  name="display_tab" checked={this.state.display_tab=='mainTab'?'chekced':''} value="mainTab"/>
						<span>Main Tab</span>
					</div> 

					<div className="form-group">
						<label>Show Product checkbox for Vet:</label><br></br>
							<input onChange={this.handleInputChange} type="radio" name="product_doctor_checkbox" checked={this.state.product_doctor_checkbox=='show'?'chekced':''} value="show"/>
						<span>Show</span>
						<input onChange={this.handleInputChange} type="radio"  name="product_doctor_checkbox" checked={this.state.product_doctor_checkbox=='hide'?'chekced':''} value="hide"/>
						<span>Hide</span>
					</div> 
					<div className="form-group">
						<label>Dashboard wider view:</label><br></br>
							<input onChange={this.handleInputChange} type="radio" name="dashboard_view" checked={this.state.dashboard_view=='1'?'chekced':''} value="1"/>
						<span>1 Column</span>
						<input onChange={this.handleInputChange} type="radio"  name="dashboard_view" checked={this.state.dashboard_view=='2'?'chekced':''} value="2"/>
						<span>2 Column</span>
					</div> 

					<div className="form-group">
						<label>Dashboard View: </label><br></br>
						<input onChange={this.handleInputChange} type="radio" name="dashboard" checked={this.state.dashboard_view_list}/>
						<span>List View</span>
							<input onChange={this.handleInputChange} type="radio"  name="dashboard" checked={this.state.dashboard_view_grid}/>
						<span>Grid View</span>
					</div> 
					<div className="form-group">
					<label>Dashboard Template: </label>					
						<Select  
							options={this.state.dahboard_template}  
							value={selected_template?selected_template:{value:'',label:'select dashboard template' }}
							onChange={this.handleChangeDahboardTemplate}
							placeholder="Select dahboard template" 
						/>			
						

					</div>		

					{this.state.selected_template.value ==1 ? <img src={template1} width={100} height={100}  onClick={this.showTemplatePopup}  />:''}
					{this.state.selected_template.value ==2 ? <img src={template2} width={100} height={100} onClick={this.showTemplatePopup} />:''}
					{this.state.selected_template.value ==3 ? <img src={template3} width={100} height={100} onClick={this.showTemplatePopup} />:''}

					
				</div>
			</div> 
			

		   <div className="title">
				<h4>Stripe Configuration</h4>
		   </div> 
		   <div className="form-group">
				    <input onChange={this.handleInputChange} type="radio" name="settings_enable_live" checked={this.state.stripe_live_checked}/>
	                <span>Live</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="settings_enable_sandbox" checked={this.state.stripe_sandbox_checked}/>
	                <span>SandBox</span>
            </div> 
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Public Key<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="text" className="form-control" name="settings_public_key" onChange={this.handleInputChange}  value={this.state.settings_public_key}/>
					<div className="errorsettings_public_key"></div>
				</div>

				<div className="form-group">
					<label>Product Key<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="text" className="form-control" name="settings_product_key" onChange={this.handleInputChange}  value={this.state.settings_product_key}/>
					<div className="errorsettings_product_key"></div>
					<span>Note : For Recurring payment(Monthly) the product key use.</span>
				</div>
					
			</div>	
			<div className="form-right">
				
				<div className="form-group">					
					<label>Secret Key<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<input type="password" className="form-control" name="settings_secret_key"  onChange={this.handleInputChange}  value={this.state.settings_secret_key} />
					<div className="errorsettings_secret_key"></div>
				</div>	
			</div>	
      	</div>		
			<div className="btn-group export">	
				
                <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                <span className="load-data">Loading</span> 
                                } Submit
			    </button>	
			</div>
				<div className=" template-action template-popup" >
					<a className='pet-close' onClick={this.closeTemplatePopup} href="javascript:void(0);">x</a>
					<p>{this.state.selected_template.value ==1 ? <img src={template1} width={250} height={250} />:''}
					{this.state.selected_template.value ==2 ? <img src={template2} width={250} height={250} />:''}
					{this.state.selected_template.value ==3 ? <img src={template3} width={250} height={250} />:''}</p>
				</div>

			</div>
			</form>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
	settings: state.settings,
	updatesetting: state.updatesetting,
	fontname : state.fontname
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	   getSettingData: (formPayload) =>{
       dispatch({ type: GET_SETTINGDATA, formPayload});
       },
       getFontName: () => {
        dispatch({ type: GET_FONTNAME });
      },
      getUpdateSetting: (formPayload) => {
      dispatch({ type: GET_UPDATESETTING, formPayload });
   	  }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Setting));